import {BasicsClass} from "@/common/BasicsClass";
import {Util} from "@/common/util";

export default class CoachListAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getAssistantList', {
            url: '/assistant/list',
            name: 'list',
            label: '列表'
        }],
        ['addAssistant', {
            url: '/assistant/add',
            name: 'add',
            label: '添加'
        }],
        ['editAssistant', {
            url: '/assistant/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['deleteAssistant', {
            url: '/assistant/delete',
            name: 'delete',
            label: '删除'
        }],
    ])


    public assistantlist(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getAssistantList').url
        const start_date: string = Util.handleStartTimeAndEndTime(params.time, false).startTime
        const end_date: string = Util.handleStartTimeAndEndTime(params.time, false).endTime

        this.BasicPost(
            url,
            {
                ...params,
                start_date: start_date,
                end_date: end_date
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
    public assistantadd(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('addAssistant').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public assistantedit(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('editAssistant').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    public assistantdelete(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('deleteAssistant').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
